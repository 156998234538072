<template>
  <component
    :is="iconId"
    :color="color"
    class="icon-wrapper" />
</template>
<script>
export default {
  name: 'ProjectDetailsSetColoredIconTemplate',
  components: {
    'Icon_template_eab2d9ec-377f-4d64-891a-4701467317d4': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_eab2d9ec-377f-4d64-891a-4701467317d4`),
    'Icon_template_cdb943fa-f981-4547-98ce-f530a1ab6e23': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_cdb943fa-f981-4547-98ce-f530a1ab6e23`),
    'Icon_template_e38da145-2e5d-457b-9fdf-f882e73ac0ae': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_e38da145-2e5d-457b-9fdf-f882e73ac0ae`),
    'Icon_template_a02f3f27-5c91-4d25-826c-bde84a0fc969': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_a02f3f27-5c91-4d25-826c-bde84a0fc969`),
    'Icon_template_73c7bb43-c0a8-4b9f-a128-b9c7bcfdfa93': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_73c7bb43-c0a8-4b9f-a128-b9c7bcfdfa93`),
    'Icon_template_331ca54c-b464-4ba7-af0f-a8157aaaed00': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_331ca54c-b464-4ba7-af0f-a8157aaaed00`),
    'Icon_template_2672160b-25a7-4f89-9427-46ceea9b7639': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_2672160b-25a7-4f89-9427-46ceea9b7639`),
    'Icon_template_a8a8151d-c0f8-40f4-bbf0-6c70575d34b6': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_a8a8151d-c0f8-40f4-bbf0-6c70575d34b6`),
    'Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c': () => import(`../ProjectDetailsSetColoredIconTemplates/Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c`),
  },
  props: {
    color: {
      type: String,
      default: '#979797',
    },
    iconId: {
      type: String,
      default: 'Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c',
    },
  },
};
</script>
<style scoped lang="scss">
  .icon-wrapper {
    width: 37px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
</style>
