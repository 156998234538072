var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","content-class":"edit-menu","disabled":_vm.shareLink,"close-on-content-click":false,"nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex"},on),[_c('IconTemplate',{attrs:{"icon-id":_vm.projectIconId ? ("Icon_template_" + _vm.projectIconId) : undefined,"color":_vm.projectColor}})],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-0",attrs:{"min-height":"230","width":"240"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between",staticStyle:{"border-bottom":"1px solid lightgrey"}},_vm._l((_vm.pallete.default_colors),function(color){return _c('span',{key:color.uuid,staticClass:"color-unit d-flex justify-center pointer",style:({ background: color.hex }),on:{"click":function($event){return _vm.setColorIcon({
          projectId: _vm.$route.params.id,
          colorId: color.uuid,
        })}}},[(_vm.projectColor === color.hex)?_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v(" mdi-check ")]):_vm._e()],1)}),0),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"px-5 pt-5"},_vm._l((_vm.pallete.default_icons),function(icon){return _c('v-col',{key:icon.uuid,staticClass:"pa-2 d-flex align-center justify-center",attrs:{"cols":"4"},on:{"click":function($event){return _vm.setColorIcon({
            projectId: _vm.$route.params.id,
            iconId: icon.uuid,
          })}}},[_c('IconTemplate',{attrs:{"color":_vm.projectIconId === icon.uuid ? _vm.projectColor : undefined,"icon-id":("Icon_template_" + (icon.uuid))}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }